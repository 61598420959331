import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Avatar, Box, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CountryForm from './CountryForm';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function CountryList() {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 100);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [countryIdToDelete, setCountryIdToDelete] = useState(null);
    const [editingCountry, setEditingCountry] = useState(null);

    const handleDelete = (id) => {
        axios.delete(`${process.env.REACT_APP_API_COUNTRIES_URL}${id}`)
            .then(() => {
                loadCountries();
                toast.success('Country deleted successfully', { autoClose: 1000 });
            })
            .catch(error => {
                toast.error('Error deleting country', { autoClose: 1000 });
                console.error('Error deleting data: ', error);
            });
    };

    const handleDeleteClick = (id) => {
        setCountryIdToDelete(id);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        handleDelete(countryIdToDelete);
        setDeleteDialogOpen(false);
        setCountryIdToDelete(null);
    };

    const handleEditClick = (country) => {
        setEditingCountry(country);
        setOpen(true);
    };

    const columns = [
        {
            field: 'flag',
            headerName: 'Flag',
            width: 100,
            renderCell: (params) => (
                <Avatar alt={params.row.name} src={params.value} />
            )
        },
        { field: 'name', headerName: 'Name', width: 200 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEditClick(params.row)} color="primary">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(params.row.id)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        }
    ];

    useEffect(() => {
        loadCountries();
        const handleResize = () => setGridHeight(window.innerHeight - 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadCountries = () => {
        axios.get(process.env.REACT_APP_API_COUNTRIES_URL)
            .then(response => {
                setRows(response.data.map(country => ({
                    id: country.id,
                    flag: country.flag,
                    name: country.name
                })));
            })
            .catch(error => {
                toast.error('Error fetching countries', { autoClose: 1000 });
                console.error('Error fetching data: ', error);
            });
    };

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setOpen(false);
        setEditingCountry(null);
    };

    const handleSubmit = (formData) => {
        if (editingCountry) {
            axios.put(`${process.env.REACT_APP_API_COUNTRIES_URL}${editingCountry.id}`, formData)
                .then(() => {
                    handleClose();
                    loadCountries();
                    toast.success('Country updated successfully', { autoClose: 1000 });
                })
                .catch(error => {
                    toast.error('Error updating country', { autoClose: 1000 });
                    console.error('Error updating data: ', error);
                });
        } else {
            axios.post(process.env.REACT_APP_API_COUNTRIES_URL, formData)
                .then(() => {
                    handleClose();
                    loadCountries();
                    toast.success('Country created successfully', { autoClose: 1000 });
                })
                .catch(error => {
                    toast.error('Error creating country', { autoClose: 1000 });
                    console.error('Error posting data: ', error);
                });
        }
    };

    return (
        <div style={{ width: '100%', height: gridHeight, position: 'relative' }}>
            <CountryForm open={open} handleClose={handleClose} handleSubmit={handleSubmit} initialData={editingCountry} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5, 10]}
                disableSelectionOnClick
                style={{ height: '100%' }}
            />
            <Box
                position="absolute"
                bottom={16}
                left={16}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                >
                    Create
                </Button>
            </Box>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this country?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}