import React, { useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Box, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import OrganisationForm from './OrganisationForm';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function OrganisationList() {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 100);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [organisationIdToDelete, setOrganisationIdToDelete] = useState(null);
    const [editingOrganisation, setEditingOrganisation] = useState(null);
    const navigate = useNavigate();

    const handleDelete = (id) => {
        axios.delete(`${process.env.REACT_APP_API_ORGANISATIONS_URL}${id}`)
            .then(() => {
                loadOrganisations();
                toast.success('Organisation deleted successfully', { autoClose: 1000 });
            })
            .catch(error => {
                toast.error('Error deleting organisation', { autoClose: 1000 });
                console.error('Error deleting data: ', error);
            });
    };

    const handleDeleteClick = (id) => {
        setOrganisationIdToDelete(id);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        handleDelete(organisationIdToDelete);
        setDeleteDialogOpen(false);
        setOrganisationIdToDelete(null);
    };

    const handleEditClick = (id) => {
        axios.get(`${process.env.REACT_APP_API_ORGANISATIONS_URL}${id}`)
            .then(response => {
                setEditingOrganisation(response.data);
                setOpen(true);
            })
            .catch(error => {
                toast.error('Error fetching organisation data', { autoClose: 1000 });
                console.error('Error fetching data: ', error);
            });
    };

    const handleShowClick = (id) => {
        navigate(`/show/${id}`);
    };

    const columns = [
        { field: 'country', headerName: 'Country', width: 200 },
        { field: 'name', headerName: 'Name', width: 200 },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleShowClick(params.row.id)} color="default">
                        <VisibilityIcon />
                    </IconButton>
                    <IconButton onClick={() => handleEditClick(params.row.id)} color="primary">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(params.row.id)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        }
    ];

    useEffect(() => {
        loadOrganisations();
        const handleResize = () => setGridHeight(window.innerHeight - 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadOrganisations = () => {
        axios.get(process.env.REACT_APP_API_ORGANISATIONS_URL)
            .then(response => {
                setRows(response.data.map(organisation => ({
                    id: organisation.id,
                    name: organisation.name,
                    country: organisation.organisation_data.country // Aquí añadimos el campo country
                })));
            })
            .catch(error => {
                toast.error('Error fetching organisations', { autoClose: 1000 });
                console.error('Error fetching data: ', error);
            });
    };

    const handleOpen = () => {
        setEditingOrganisation(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditingOrganisation(null);
    };

    const handleSubmit = (formData) => {
        if (editingOrganisation) {
            axios.put(`${process.env.REACT_APP_API_ORGANISATIONS_URL}${editingOrganisation.id}`, formData)
                .then(() => {
                    handleClose();
                    loadOrganisations();
                    toast.success('Organisation updated successfully', { autoClose: 1000 });
                })
                .catch(error => {
                    toast.error('Error updating organisation', { autoClose: 1000 });
                    console.error('Error updating data: ', error);
                });
        } else {
            axios.post(process.env.REACT_APP_API_ORGANISATIONS_URL, formData)
                .then(() => {
                    handleClose();
                    loadOrganisations();
                    toast.success('Organisation created successfully', { autoClose: 1000 });
                })
                .catch(error => {
                    toast.error('Error creating organisation', { autoClose: 1000 });
                    console.error('Error posting data: ', error);
                });
        }
    };

    return (
        <div style={{ width: '100%', height: gridHeight, position: 'relative' }}>
            <OrganisationForm open={open} handleClose={handleClose} handleSubmit={handleSubmit} initialData={editingOrganisation} />
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20]}
                disableSelectionOnClick
                style={{ height: '100%' }}
            />
            <Box
                position="absolute"
                bottom={16}
                left={16}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                >
                    Create
                </Button>
            </Box>
            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this organisation?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
