import {Avatar, Box, IconButton, Menu, MenuItem} from "@mui/material";
import {useState} from "react";

function ProfileBar({ flagUrl }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <Box display="flex" alignItems="center" padding={1} bgcolor="background.paper" borderRadius="borderRadius">
            <IconButton onClick={handleClick}>
                <Avatar src={flagUrl} alt="Profile Icon" sx={{ width: 56, height: 56 }} />
            </IconButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={handleClose}>Configuration</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
            </Menu>
        </Box>
    );
}

export default ProfileBar;
