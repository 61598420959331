import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { Card, CardContent, CardActions, Typography, IconButton, Box } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const OrganisationDetail = () => {
    const [organisation, setOrganisation] = useState({});
    const [app, setApp] = useState({});
    const { id } = useParams();

    useEffect(() => {
        fetchOrganisation();
    }, [id]);

    const fetchOrganisation = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_ORGANISATIONS_URL}${id}`);
            setOrganisation(response.data);

            if (response.data.app_id) {
                fetchApp(response.data.app_id);
            }
        } catch (error) {
            console.error('Error fetching organisation:', error);
        }
    };

    const fetchApp = async (appId) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_APP_IDENTIFIERS_URL}${appId}`);
            setApp(response.data);
        } catch (error) {
            console.error('Error fetching app:', error);
        }
    };

    return (
        <Box sx={{ maxWidth: 800, margin: 'auto', mt: 4 }}>
            <Card>
                <CardContent>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="h5" component="div">
                            Organisation Detail
                        </Typography>
                        <Link to="/organisations">
                            <IconButton>
                                <ArrowBackIcon />
                            </IconButton>
                        </Link>
                    </Box>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Id:</strong> {organisation.id}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Name:</strong> {organisation.name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Address:</strong> {organisation.organisation_data?.address}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>City, State, Country:</strong> {organisation.organisation_data?.city_state_country}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Email:</strong> {organisation.organisation_data?.email}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Website:</strong> <a href={organisation.organisation_data?.website} target="_blank" rel="noopener noreferrer">{organisation.organisation_data?.website}</a>
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Contact Person:</strong> {organisation.organisation_data?.contact_person}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Phone Number:</strong> {organisation.organisation_data?.phone_number}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Notes:</strong> {organisation.organisation_data?.notes}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>App:</strong> {app.app_name}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Created At:</strong> {new Date(organisation.created_at).toLocaleString()}
                    </Typography>
                    <Typography variant="body1" color="textSecondary" component="p">
                        <strong>Updated At:</strong> {new Date(organisation.updated_at).toLocaleString()}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Link to={`/edit/${organisation.id}`}>
                        <IconButton color="primary">
                            <EditIcon />
                        </IconButton>
                    </Link>
                </CardActions>
            </Card>
        </Box>
    );
};

export default OrganisationDetail;
