import React, { useEffect, useState, useCallback } from 'react';
import { DataGrid,GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Box, Button, IconButton, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import GroupForm from './GroupForm';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function GroupList() {
    const [rows, setRows] = useState([]);
    const [open, setOpen] = useState(false);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 100);
    const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
    const [groupIdToDelete, setGroupIdToDelete] = useState(null);
    const [editingGroup, setEditingGroup] = useState(null);
    const [page, setPage] = useState(0);
    const [pageSize, setPageSize] = useState(25);
    const [rowCount, setRowCount] = useState(0);
    const [filterModel, setFilterModel] = useState({ items: [] });
    const navigate = useNavigate();

    const loadGroups = useCallback((page, pageSize, filters) => {
        const filterParams = filters.items.map(filter => `${filter.columnField}=${filter.value}`).join('&');
        axios.get(`${process.env.REACT_APP_API_GROUPS_URL}?page=${page + 1}&page_size=${pageSize}&${filterParams}`)
            .then(response => {
                setRows(response.data.data.map(group => ({
                    id: group.id,
                    name: group.name,
                    email: group.group_data?.email || '',
                    facebook: group.group_data?.facebook || '',
                    organisation: group.organisation_name,
                    country: group.country_name
                })));
                setRowCount(response.data.total_count);
            })
            .catch(error => {
                toast.error('Error fetching groups', { autoClose: 1000 });
                console.error('Error fetching data: ', error);
            });
    }, []);

    useEffect(() => {
        loadGroups(page, pageSize, filterModel);
    }, [page, pageSize, filterModel, loadGroups]);

    const handleDelete = (id) => {
        axios.delete(`${process.env.REACT_APP_API_GROUPS_URL}${id}`)
            .then(() => {
                loadGroups(page, pageSize, filterModel);
                toast.success('Group deleted successfully', { autoClose: 1000 });
            })
            .catch(error => {
                toast.error('Error deleting group', { autoClose: 1000 });
                console.error('Error deleting data: ', error);
            });
    };

    const handleDeleteClick = (id) => {
        setGroupIdToDelete(id);
        setDeleteDialogOpen(true);
    };

    const confirmDelete = () => {
        handleDelete(groupIdToDelete);
        setDeleteDialogOpen(false);
        setGroupIdToDelete(null);
    };

    const handleEditClick = (id) => {
        axios.get(`${process.env.REACT_APP_API_GROUPS_URL}${id}`)
            .then(response => {
                setEditingGroup(response.data);
                setOpen(true);
            })
            .catch(error => {
                toast.error('Error fetching group data', { autoClose: 1000 });
                console.error('Error fetching data: ', error);
            });
    };

    const columns = [
        { field: 'name', headerName: 'Group Name', width: 200, filterable: true },
        { field: 'organisation', headerName: 'Organisation', width: 200, filterable: true },
        { field: 'country', headerName: 'Country', width: 200, filterable: true },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 200,
            renderCell: (params) => (
                <div>
                    <IconButton onClick={() => handleEditClick(params.row.id)} color="primary">
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteClick(params.row.id)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </div>
            ),
        }
    ];

    const handleOpen = () => {
        setEditingGroup(null);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setEditingGroup(null);
    };

    const handleSubmit = (formData) => {
        if (editingGroup) {
            axios.put(`${process.env.REACT_APP_API_GROUPS_URL}${editingGroup.id}`, formData)
                .then(() => {
                    handleClose();
                    loadGroups(page, pageSize, filterModel);
                    toast.success('Group updated successfully', { autoClose: 1000 });
                })
                .catch(error => {
                    toast.error('Error updating group', { autoClose: 1000 });
                    console.error('Error updating data: ', error);
                });
        } else {
            axios.post(process.env.REACT_APP_API_GROUPS_URL, formData)
                .then(() => {
                    handleClose();
                    loadGroups(page, pageSize, filterModel);
                    toast.success('Group created successfully', { autoClose: 1000 });
                })
                .catch(error => {
                    toast.error('Error creating group', { autoClose: 1000 });
                    console.error('Error posting data: ', error);
                });
        }
    };

    useEffect(() => {
        const handleResize = () => setGridHeight(window.innerHeight - 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div style={{ width: '100%', height: gridHeight, position: 'relative' }}>
            <GroupForm open={open} handleClose={handleClose} handleSubmit={handleSubmit} initialData={editingGroup} />
            <Box
                position="absolute"
                top={0}
                right={0}
            >
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleOpen}
                >
                    Create
                </Button>
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={pageSize}
                rowsPerPageOptions={[10, 25, 50, 100]}
                paginationMode="server"
                rowCount={rowCount}
                pagination
                onPaginationModelChange={(model) => {
                    setPage(model.page);
                    setPageSize(model.pageSize);
                }}
                filterModel={filterModel}
                onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
                disableSelectionOnClick
                style={{ height: '100%' }}
                slots={{ toolbar: GridToolbar }}
            />

            <Dialog
                open={deleteDialogOpen}
                onClose={() => setDeleteDialogOpen(false)}
            >
                <DialogTitle>{"Confirm Delete"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to delete this group?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setDeleteDialogOpen(false)} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={confirmDelete} color="primary" autoFocus>
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
