import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Authenticator} from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';

import Sidebar from './Sidebar';
import AuditItemList from "./AuditItemList";
import OrganisationList from "./OrganisationList";

import {Amplify} from "aws-amplify";
import ProfileBar from "./ProfileBar";

import awsExports from './aws-exports';

import './App.css';
import CountryList from "./CountryList";
import OrganisationDetail from "./OrganisationDetail";
import EditableAppIdentifierGrid from "./EditableAppIdentifierGrid";
import EditableLanguageGrid from "./EditableLanguageGrid";
import GroupList from "./GroupList";

Amplify.configure(awsExports);

export default function App() {
    return (
        <div className="App">
            <Authenticator>
                {({signOut, user}) => (
                    <Router>
                        <Sidebar/>
                        <div style={{flex: 1, display: 'flex', flexDirection: 'column'}}>
                            <div style={{flex: 0, padding: '10px', alignSelf: 'flex-end'}}>
                                <ProfileBar user={user} signOut={signOut}/>
                            </div>
                            <div style={{flex: 1, padding: '10px'}}>
                                <Routes>
                                    <Route path="/audit-items" element={<AuditItemList/>}/>
                                    <Route path="/groups" element={<GroupList/>}/>
                                    <Route path="/countries" element={<CountryList/>}/>
                                    <Route path="/organisations" element={<OrganisationList/>}/>
                                    <Route path="/show/:id" element={<OrganisationDetail/>}/>
                                    <Route path="/app-identifier" element={<EditableAppIdentifierGrid/>}/>
                                    <Route path="/languages" element={<EditableLanguageGrid/>}/>
                                    {/* Add more routes as needed */}
                                </Routes>
                            </div>
                        </div>
                    </Router>
                )}
            </Authenticator>
        </div>
    );
}

