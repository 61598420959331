import React, { useState, useEffect } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Box, Button, IconButton, TextField, Select, MenuItem } from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const EditableAppIdentifierGrid = () => {
    const [rows, setRows] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 100);
    const [editingId, setEditingId] = useState(null);
    const [newRow, setNewRow] = useState({ id: null, app_name: '', language: '' });

    useEffect(() => {
        loadAppIdentifiers();
        loadLanguages();
        const handleResize = () => setGridHeight(window.innerHeight - 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadAppIdentifiers = () => {
        axios.get(process.env.REACT_APP_API_APP_IDENTIFIERS_URL)
            .then(response => {
                setRows(response.data.map(item => ({
                    id: item.id,
                    app_name: item.app_name,
                    language: item.language
                })));
            })
            .catch(error => console.error('Error fetching data: ', error));
    };

    const loadLanguages = () => {
        axios.get(process.env.REACT_APP_API_LANGUAGES_URL)
            .then(response => {
                setLanguages(response.data.map(language => ({
                    id: language.id,
                    name: language.language
                })));
            })
            .catch(error => console.error('Error fetching languages: ', error));
    };

    const handleEditChange = (id, field, value) => {
        setRows(prevRows => prevRows.map(row => (row.id === id ? { ...row, [field]: value } : row)));
    };

    const handleSave = (row) => {
        if (typeof row.id === 'string' && row.id.startsWith('new-')) {
            const newAppIdentifier = {
                app_name: row.app_name,
                language: row.language
            };
            axios.post(process.env.REACT_APP_API_APP_IDENTIFIERS_URL, newAppIdentifier)
                .then(() => {
                    setNewRow({ id: null, app_name: '', language: '' });
                    loadAppIdentifiers();
                })
                .catch(error => console.error('Error adding data: ', error));
        } else {
            const updatedAppIdentifier = {
                app_name: row.app_name,
                language: row.language
            };
            axios.put(`${process.env.REACT_APP_API_APP_IDENTIFIERS_URL}${row.id}`, updatedAppIdentifier)
                .then(() => {
                    setEditingId(null); // Desactivar la edición
                    loadAppIdentifiers();
                })
                .catch(error => console.error('Error updating data: ', error));
        }
    };

    const handleDelete = (id) => {
        axios.delete(`${process.env.REACT_APP_API_APP_IDENTIFIERS_URL}${id}`)
            .then(() => loadAppIdentifiers())
            .catch(error => console.error('Error deleting data: ', error));
    };

    const handleEditClick = (id) => {
        setEditingId(id);
    };

    const handleAddNew = () => {
        const tempId = `new-${Date.now()}`;
        setRows(prevRows => [...prevRows, { ...newRow, id: tempId }]);
        setEditingId(tempId);
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 100 },
        {
            field: 'app_name',
            headerName: 'App Name',
            width: 200,
            renderCell: (params) => (
                editingId === params.id ? (
                    <TextField
                        value={params.value}
                        onChange={(e) => handleEditChange(params.id, 'app_name', e.target.value)}
                    />
                ) : params.value
            )
        },
        {
            field: 'language',
            headerName: 'Language',
            width: 200,
            renderCell: (params) => (
                editingId === params.id ? (
                    <Select
                        value={params.value}
                        onChange={(e) => handleEditChange(params.id, 'language', e.target.value)}
                        displayEmpty
                        fullWidth
                    >
                        <MenuItem value=""><em>None</em></MenuItem>
                        {languages.map(language => (
                            <MenuItem key={language.id} value={language.name}>{language.name}</MenuItem>
                        ))}
                    </Select>
                ) : params.value
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    {editingId === params.id ? (
                        <IconButton onClick={() => handleSave(params.row)} color="primary">
                            <SaveIcon />
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => handleEditClick(params.id)} color="primary">
                            <EditIcon />
                        </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(params.id)} color="error">
                        <DeleteIcon />
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <div style={{ width: '100%', height: gridHeight, position: 'relative' }}>
            <Box mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddNew}
                >
                    Add App Identifier
                </Button>
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20]}
                disableSelectionOnClick
                style={{ height: '100%' }}
            />
        </div>
    );
};

export default EditableAppIdentifierGrid;
