import React, {useState, useEffect} from 'react';
import {DataGrid} from '@mui/x-data-grid';
import axios from 'axios';
import {Box, Button, IconButton, TextField} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import AddIcon from '@mui/icons-material/Add';

const EditableLanguageGrid = () => {
    const [rows, setRows] = useState([]);
    const [gridHeight, setGridHeight] = useState(window.innerHeight - 100);
    const [editingId, setEditingId] = useState(null); // ID of the record being edited
    const [newRow, setNewRow] = useState({id: null, language: ''});

    useEffect(() => {
        loadLanguages();
        const handleResize = () => setGridHeight(window.innerHeight - 100);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const loadLanguages = () => {
        axios.get(`${process.env.REACT_APP_API_LANGUAGES_URL}`)
            .then(response => {
                setRows(response.data.map(item => ({
                    id: item.id,
                    language: item.language
                })));
            })
            .catch(error => console.error('Error fetching data: ', error));
    };

    const handleEditChange = (id, field, value) => {
        setRows(prevRows => prevRows.map(row => (row.id === id ? {...row, [field]: value} : row)));
    };

    const handleSave = (row) => {
        if (typeof row.id === 'string' && row.id.startsWith('new-')) {
            // New entry
            const newLanguage = {
                language: row.language
            };
            axios.post(`${process.env.REACT_APP_API_LANGUAGES_URL}`, newLanguage)
                .then(() => {
                    setNewRow({id: null, language: ''});
                    loadLanguages();
                })
                .catch(error => console.error('Error adding data: ', error));
        } else {
            // Existing entry
            const updatedLanguage = {
                language: row.language
            };
            axios.put(`${process.env.REACT_APP_API_LANGUAGES_URL}${row.id}`, updatedLanguage)
                .then(() => {
                    setEditingId(null); // Disable editing
                    loadLanguages();
                })
                .catch(error => console.error('Error updating data: ', error));
        }
    };

    const handleDelete = (id) => {
        axios.delete(`${process.env.REACT_APP_API_LANGUAGES_URL}${id}`)
            .then(() => loadLanguages())
            .catch(error => console.error('Error deleting data: ', error));
    };

    const handleEditClick = (id) => {
        setEditingId(id); // Enable editing for the specific ID
    };

    const handleAddNew = () => {
        const tempId = `new-${Date.now()}`;
        setRows(prevRows => [...prevRows, {...newRow, id: tempId}]);
        setEditingId(tempId);
    };

    const columns = [
        {field: 'id', headerName: 'ID', width: 100},
        {
            field: 'language',
            headerName: 'Language',
            width: 200,
            renderCell: (params) => (
                editingId === params.id ? (
                    <TextField
                        value={params.value}
                        onChange={(e) => handleEditChange(params.id, 'language', e.target.value)}
                    />
                ) : params.value
            )
        },
        {
            field: 'actions',
            headerName: 'Actions',
            width: 150,
            renderCell: (params) => (
                <div>
                    {editingId === params.id ? (
                        <IconButton onClick={() => handleSave(params.row)} color="primary">
                            <SaveIcon/>
                        </IconButton>
                    ) : (
                        <IconButton onClick={() => handleEditClick(params.id)} color="primary">
                            <EditIcon/>
                        </IconButton>
                    )}
                    <IconButton onClick={() => handleDelete(params.id)} color="error">
                        <DeleteIcon/>
                    </IconButton>
                </div>
            )
        }
    ];

    return (
        <div style={{width: '100%', height: gridHeight, position: 'relative'}}>
            <Box mb={2}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon/>}
                    onClick={handleAddNew}
                >
                    Add Language
                </Button>
            </Box>
            <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 20, 30, 50, 100]}
                disableSelectionOnClick
                style={{height: '100%'}}
            />
        </div>
    );
};

export default EditableLanguageGrid;