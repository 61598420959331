import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OrganisationForm = ({ open, handleClose, handleSubmit, initialData }) => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [cityStateCountry, setCityStateCountry] = useState('');
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState([]);
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [notes, setNotes] = useState('');
    const [appId, setAppId] = useState('');
    const [apps, setApps] = useState([]);

    useEffect(() => {
        if (initialData) {
            setName(initialData.name);
            setAddress(initialData.organisation_data?.address || '');
            setCityStateCountry(initialData.organisation_data?.city_state_country || '');
            setCountry(initialData.organisation_data?.country || '');
            setEmail(initialData.organisation_data?.email || '');
            setWebsite(initialData.organisation_data?.website || '');
            setContactPerson(initialData.organisation_data?.contact_person || '');
            setPhoneNumber(initialData.organisation_data?.phone_number || '');
            setNotes(initialData.organisation_data?.notes || '');
            setAppId(initialData.app_id || '');
        } else {
            resetForm();
        }
        fetchApps();
        fetchCountries();
    }, [initialData]);

    const fetchApps = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_APP_IDENTIFIERS_URL);
            setApps(response.data);
        } catch (error) {
            toast.error('Error fetching apps', { autoClose: 1000 });
            console.error('Error fetching apps: ', error);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_COUNTRIES_URL);
            const sortedCountries = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries);
        } catch (error) {
            toast.error('Error fetching countries', { autoClose: 1000 });
            console.error('Error fetching countries: ', error);
        }
    };

    const resetForm = () => {
        setName('');
        setAddress('');
        setCityStateCountry('');
        setCountry('');
        setEmail('');
        setWebsite('');
        setContactPerson('');
        setPhoneNumber('');
        setNotes('');
        setAppId('');
    };

    const onSubmit = () => {
        const formData = {
            name,
            organisation_data: {
                address,
                city_state_country: cityStateCountry,
                country,
                email,
                website,
                contact_person: contactPerson,
                phone_number: phoneNumber,
                notes
            },
            app_id: appId
        };
        handleSubmit(formData);
        resetForm(); // Reset form after submission
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{initialData ? 'Edit Organisation' : 'Create Organisation'}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Address"
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="City, State, Country"
                    fullWidth
                    value={cityStateCountry}
                    onChange={(e) => setCityStateCountry(e.target.value)}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="country-select-label">Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        value={country}
                        onChange={(e) => setCountry(e.target.value)}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country.code} value={country.name}>{country.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Website"
                    fullWidth
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Contact Person"
                    fullWidth
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Phone Number"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Notes"
                    fullWidth
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="app-select-label">Select App</InputLabel>
                    <Select
                        labelId="app-select-label"
                        value={appId}
                        onChange={(e) => setAppId(e.target.value)}
                    >
                        {apps.map((app) => (
                            <MenuItem key={app.id} value={app.id}>{app.app_name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default OrganisationForm;
