import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Select, MenuItem, InputLabel, FormControl, Switch, FormControlLabel } from '@mui/material';
import axios from 'axios';

const GroupForm = ({ open, handleClose, handleSubmit, initialData }) => {
    const [name, setName] = useState('');
    const [address, setAddress] = useState('');
    const [countryId, setCountryId] = useState('');
    const [email, setEmail] = useState('');
    const [website, setWebsite] = useState('');
    const [contactPerson, setContactPerson] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [notes, setNotes] = useState('');
    const [organisationId, setOrganisationId] = useState('');
    const [organisations, setOrganisations] = useState([]);
    const [countries, setCountries] = useState([]);
    const [donorbox, setDonorbox] = useState(false);
    const [facebook, setFacebook] = useState('');
    const [tiktok, setTiktok] = useState('');
    const [instagram, setInstagram] = useState('');
    const [languageId, setLanguageId] = useState('');
    const [languages, setLanguages] = useState([]);
    const [auditItemId, setAuditItemId] = useState('');
    const [auditItems, setAuditItems] = useState([]);

    useEffect(() => {
        if (initialData) {
            setName(initialData.name || '');
            setAddress(initialData.group_data?.address || '');
            setCountryId(initialData.country_id || '');
            setEmail(initialData.group_data?.email || '');
            setWebsite(initialData.group_data?.website || '');
            setContactPerson(initialData.group_data?.contact_person || '');
            setPhoneNumber(initialData.group_data?.phone_number || '');
            setNotes(initialData.group_data?.notes || '');
            setOrganisationId(initialData.organisation_id || '');
            setDonorbox(initialData.group_data?.donorbox || false);
            setFacebook(initialData.group_data?.facebook || '');
            setTiktok(initialData.group_data?.tiktok || '');
            setInstagram(initialData.group_data?.instagram || '');
            setLanguageId(initialData.language_id || '');
            setAuditItemId(initialData.audit_items_id || '');
        } else {
            resetForm();
        }
        fetchOrganisations();
        fetchCountries();
        fetchLanguages();
        fetchAuditItems();
    }, [initialData]);

    const fetchOrganisations = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_ORGANISATIONS_URL);
            setOrganisations(response.data);
        } catch (error) {
            console.error('Error fetching organisations:', error);
        }
    };

    const fetchCountries = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_COUNTRIES_URL);
            const sortedCountries = response.data.sort((a, b) => a.name.localeCompare(b.name));
            setCountries(sortedCountries);
        } catch (error) {
            console.error('Error fetching countries:', error);
        }
    };

    const fetchLanguages = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_LANGUAGES_URL);
            setLanguages(response.data);
        } catch (error) {
            console.error('Error fetching languages:', error);
        }
    };

    const fetchAuditItems = async () => {
        try {
            const response = await axios.get(process.env.REACT_APP_API_AUDIT_ITEMS_URL);
            setAuditItems(response.data);
            console.log(response.data);
        } catch (error) {
            console.error('Error fetching audit items:', error);
        }
    };

    const resetForm = () => {
        setName('');
        setAddress('');
        setCountryId('');
        setEmail('');
        setWebsite('');
        setContactPerson('');
        setPhoneNumber('');
        setNotes('');
        setOrganisationId('');
        setDonorbox(false);
        setFacebook('');
        setTiktok('');
        setInstagram('');
        setLanguageId('');
        setAuditItemId('');
    };

    const onSubmit = () => {
        const formData = {
            name,
            group_data: {
                address,
                country: countryId,
                email,
                website,
                contact_person: contactPerson,
                phone_number: phoneNumber,
                notes,
                donorbox,
                facebook,
                tiktok,
                instagram
            },
            organisation_id: organisationId,
            country_id: countryId,
            language_id: languageId,
            audit_items_id: auditItemId
        };
        handleSubmit(formData);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{initialData ? 'Edit Group' : 'Create Group'}</DialogTitle>
            <DialogContent>
                <FormControl fullWidth margin="dense">
                    <InputLabel id="organisation-select-label">Select Organisation</InputLabel>
                    <Select
                        labelId="organisation-select-label"
                        value={organisationId}
                        onChange={(e) => setOrganisationId(e.target.value)}
                    >
                        {organisations.map((org) => (
                            <MenuItem key={org.id} value={org.id}>{org.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    label="Group Name"
                    fullWidth
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Address"
                    fullWidth
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                />
                <FormControl fullWidth margin="dense">
                    <InputLabel id="country-select-label">Select Country</InputLabel>
                    <Select
                        labelId="country-select-label"
                        value={countryId}
                        onChange={(e) => setCountryId(e.target.value)}
                    >
                        {countries.map((country) => (
                            <MenuItem key={country.id} value={country.id}>{country.name}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    margin="dense"
                    label="Email"
                    fullWidth
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Website"
                    fullWidth
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Contact Person"
                    fullWidth
                    value={contactPerson}
                    onChange={(e) => setContactPerson(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Phone Number"
                    fullWidth
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                    margin="dense"
                    label="Notes"
                    fullWidth
                    value={notes}
                    onChange={(e) => setNotes(e.target.value)}
                />
                <FormControl fullWidth margin="dense" sx={{ backgroundColor: '#ffe4e1' }}>
                    <InputLabel id="language-select-label">Select Language</InputLabel>
                    <Select
                        labelId="language-select-label"
                        value={languageId}
                        onChange={(e) => setLanguageId(e.target.value)}
                    >
                        {languages.map((language) => (
                            <MenuItem key={language.id} value={language.id}>{language.language}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl fullWidth margin="dense" sx={{ backgroundColor: '#ffe4e1' }}>
                    <InputLabel id="audit-item-select-label">Select Audit Item</InputLabel>
                    <Select
                        labelId="audit-item-select-label"
                        value={auditItemId}
                        onChange={(e) => setAuditItemId(e.target.value)}
                    >
                        {auditItems.map((item) => (
                            <MenuItem key={item.id} value={item.id}>{item.category}</MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControlLabel
                    control={
                        <Switch
                            checked={donorbox}
                            onChange={(e) => setDonorbox(e.target.checked)}
                            name="donorbox"
                            color="primary"
                        />
                    }
                    label="Donorbox"
                />
                <TextField
                    margin="dense"
                    label="Enter Facebook address"
                    fullWidth
                    value={facebook}
                    onChange={(e) => setFacebook(e.target.value)}
                    sx={{ backgroundColor: '#e0f7fa' }}
                />
                <TextField
                    margin="dense"
                    label="Enter Tik Tok address"
                    fullWidth
                    value={tiktok}
                    onChange={(e) => setTiktok(e.target.value)}
                    sx={{ backgroundColor: '#e0f7fa' }}
                />
                <TextField
                    margin="dense"
                    label="Enter Instagram address"
                    fullWidth
                    value={instagram}
                    onChange={(e) => setInstagram(e.target.value)}
                    sx={{ backgroundColor: '#e0f7fa' }}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={onSubmit} color="primary">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default GroupForm;
