import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button } from '@mui/material';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Country.css';

function CountryForm({ open, handleClose, handleSubmit, initialData }) {
    const [formData, setFormData] = useState({
        name: '',
        flag: ''
    });

    useEffect(() => {
        if (initialData) {
            setFormData({
                name: initialData.name,
                flag: initialData.flag || ''
            });
        } else {
            setFormData({
                name: '',
                flag: ''
            });
        }
    }, [initialData]);

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const submitForm = () => {
        const dataToSubmit = {
            ...formData,
            flag: formData.flag === '' ? null : formData.flag
        };

        try {
            handleSubmit(dataToSubmit);
            handleClose();
        } catch (error) {
            toast.error('Error submitting form', { autoClose: 1000 });
            console.error('Error submitting form: ', error);
        }
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{initialData ? 'Edit Country' : 'Add New Country'}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Name *"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                />
                <TextField
                    margin="dense"
                    label="Flag (get URL https://www.worldometers.info/geography/flags-of-the-world/)"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="flag"
                    value={formData.flag}
                    onChange={handleChange}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary" disabled={!formData.name}>
                    {initialData ? 'Update' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default CountryForm;
