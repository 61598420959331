import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Typography } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import './AuditItem.css'

function AuditItemForm({ open, handleClose, handleSubmit, initialData }) {
    const [formData, setFormData] = useState({
        category: '',
        image: null
    });
    const [preview, setPreview] = useState('');

    useEffect(() => {
        if (initialData) {
            setFormData({
                category: initialData.category,
                image: initialData.image
            });
            setPreview(initialData.image);
        } else {
            setFormData({
                category: '',
                image: null
            });
            setPreview('');
        }
    }, [initialData]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: 'image/*',
        onDrop: acceptedFiles => {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setFormData({...formData, image: reader.result});
                setPreview(reader.result);
            };
            reader.readAsDataURL(file);
        },
        multiple: false
    });

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({...formData, [name]: value});
    };

    const submitForm = () => {
        handleSubmit(formData);
        handleClose();
    };

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
            <DialogTitle>{initialData ? 'Edit Item' : 'Add New Category'}</DialogTitle>
            <DialogContent>
                <TextField
                    margin="dense"
                    label="Category"
                    type="text"
                    fullWidth
                    variant="outlined"
                    name="category"
                    value={formData.category}
                    onChange={handleChange}
                />
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <p>Drag 'n' drop file here, or click to select file</p>
                </div>
                {preview && (
                    <div style={{ marginTop: 20, textAlign: 'center' }}>
                        <Typography variant="body2" gutterBottom>Image Preview:</Typography>
                        <img src={preview} alt="Preview" style={{ width: '512px', height: '512px', objectFit: 'contain' }} />
                    </div>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={submitForm} color="primary">
                    {initialData ? 'Update' : 'Save'}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default AuditItemForm;
