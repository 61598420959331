import React, {useState} from 'react';
import {Link} from 'react-router-dom';
import {Drawer, List, ListItem, ListItemIcon, ListItemText, Collapse, Divider} from '@mui/material';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

export default function Sidebar() {
    const [openSettings, setOpenSettings] = useState(false);
    const [openLogs, setOpenLogs] = useState(false);
    const [openOrganisations, setOpenOrganisations] = useState(false);
    const [openGroups, setOpenGroups] = useState(false);
    const [openDatabase, setOpenDatabase] = useState(false);

    const handleSettingsClick = () => {
        setOpenSettings(!openSettings);
    };

    const handleLogsClick = () => {
        setOpenLogs(!openLogs);
    };

    const handleOrganisationsClick = () => {
        setOpenOrganisations(!openOrganisations);
    };

    const handleGroupsClick = () => {
        setOpenGroups(!openGroups);
    };

    const handleDatabaseClick = () => {
        setOpenDatabase(!openDatabase);
    };

    return (
        <Drawer
            sx={{width: 240, '& .MuiDrawer-paper': {width: 240, boxSizing: 'border-box'}}}
            variant="permanent"
            anchor="left"
        >
            <List>
                <ListItem>
                    <ListItemIcon><DashboardIcon/></ListItemIcon>
                    <ListItemText primary="Dashboard"/>
                </ListItem>
                <Divider/>
                <ListItem button onClick={handleSettingsClick}>
                    <ListItemText primary="Settings"/>
                    {openSettings ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>
                <Collapse in={openSettings} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button sx={{pl: 4}} component={Link} to="/administrators">
                            <ListItemText primary="Administrators"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/countries">
                            <ListItemText primary="Country"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/audit-items">
                            <ListItemText primary="Audit Items"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/app-identifier">
                            <ListItemText primary="App Identifier"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/languages">
                            <ListItemText primary="Languages"/>
                        </ListItem>
                    </List>
                </Collapse>
                <Divider/>
                <ListItem button onClick={handleOrganisationsClick}>
                    <ListItemText primary="Organisations"/>
                    {openOrganisations ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>
                <Collapse in={openOrganisations} timeout="auto" unmountOnExit>
                    <ListItem button sx={{pl: 4}} component={Link} to="/organisations">
                        <ListItemText primary="Organisations"/>
                    </ListItem>
                </Collapse>
                <Divider/>
                <ListItem button onClick={handleGroupsClick}>
                    <ListItemText primary="Groups"/>
                    {openGroups ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>
                <Collapse in={openGroups} timeout="auto" unmountOnExit>
                    <ListItem button sx={{pl: 4}} component={Link} to="/groups">
                        <ListItemText primary="Groups"/>
                    </ListItem>
                    <ListItem button sx={{pl: 4}} component={Link} to="/group-admins">
                        <ListItemText primary="Group Admins"/>
                    </ListItem>
                </Collapse>
                <Divider/>
                <ListItem button onClick={handleLogsClick}>
                    <ListItemText primary="Logs"/>
                    {openLogs ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>
                <Collapse in={openLogs} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button sx={{pl: 4}} component={Link} to="/events">
                            <ListItemText primary="Events"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/admin-logins">
                            <ListItemText primary="Admin Logins"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/group-admin-logins">
                            <ListItemText primary="Group Admin Logins"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/database-logins">
                            <ListItemText primary="Database Logins"/>
                        </ListItem>
                        <ListItem button sx={{pl: 4}} component={Link} to="/app-logins">
                            <ListItemText primary="App Logins"/>
                        </ListItem>
                    </List>
                </Collapse>
                <Divider/>
                <ListItem button onClick={handleDatabaseClick}>
                    <ListItemText primary="Database"/>
                    {openDatabase ? <ExpandLessIcon/> : <ExpandMoreIcon/>}
                </ListItem>
                <Collapse in={openDatabase} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem button sx={{pl: 4}} component={Link} to="/searches">
                            <ListItemText primary="Searches"/>
                        </ListItem>
                    </List>
                </Collapse>
            </List>
        </Drawer>
    );
}
